// Here you can add other styles
.app-header .nav-item .nav-link > .img-avatar {
  margin-left: 30px;
}

* {
  color: black;
}

.layout-add-button {
  float: left;
  font-size: 30px;
  color: #20a8d8;
  cursor: pointer;
}

.position-relative.row.form-group {
  align-items: center;
}

.table {
  overflow: hidden;
}

.table th,
.table td {
  text-align: center;
}

#preview-section {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 400px;
  max-width: 400px;
  direction: ltr;
  margin: 0 auto;
}

.edit-preview {
  margin-right: 0 !important;
}

.save-finish-button {
  margin: 1.25rem;
  margin-bottom: 0;
}

.wizard-button,
.save-finish-button {
  font-size: 16px;
}

#conversation-client,
#conversation-client > div {
  height: 667px;
  max-height: 667px;
  position: relative;
}

.btn-success:hover,
.btn-danger:hover,
.btn-warning:hover,
.btn-secondary:hover,
.btn-info:hover {
  color: black !important;
  background-color: inherit !important;
}

.no-padding {
  padding: 0 !important;
}

.steps-table {
  border: none;
}

.sidebar .nav-link {
  font-size: 16px;
}

.login-input.form-control {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

span.login-icon.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.modal {
  top: 25%;
}

.modal-header .close {
  margin: -1rem -1rem -1rem 0;
}

.main-nav {
  position: absolute;
  width: 100%;
  left: 0;
}

.hidden {
  display: none;
}

.not-hidden {
  display: initial;
}

.paddingTopTable {
  padding-top: 70px;
}
.paddingDraftTable {
  padding-top: 20px;
}
.alert-danger {
  border: 0;
  border-radius: 0;
}

#heading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

input[type="number"] {
  max-width: 55px !important;
  padding: 5px;
}

.main-containter {
  position: relative;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.form-check-label {
  margin-right: 5px;
}

.pagination {
  float: right;
  padding: 0;
}

.page-item:last-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item:first-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-footer {
  justify-content: space-between !important;
}

.card-footer {
  background-color: initial;
}

.btn-block {
  color: #fff;
}

.modal-danger .modal-content {
  border-color: cornflowerblue;
}

.modal-danger .modal-header {
  background-color: cornflowerblue;
}

.modal-back-btn {
  margin: 0 auto;
}

.navbar-brand img {
  height: 100%;
  padding: 5px;
}

.layout-link {
  width: 100%;
  margin-bottom: 10px;
  text-align: left;
  direction: ltr;
}
.w-100 {
  width: 100%;
}
textarea {
  resize: none;
}
.sketch-picker {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  /* bring your own prefixes */
  transform: translate(-50%, -50%) !important;
}
